import { Breadcrumb } from '../components/Breadcrumbs/Breadcrumbs'
import { Faq } from '../components/FAQs/faqs-list-interface'
import { TestimonialProps } from '../components/Testimonials/Testimonial'

/* eslint-disable @typescript-eslint/no-explicit-any */

export const generateBreadcrumbsSchema = (breadcrumbs: Breadcrumb[]): any => {
  return {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: breadcrumbs.map((b, i) => {
      return {
        '@type': 'ListItem',
        position: i + 1,
        item: {
          '@id': `https://www.getmegarummy.com${b.url}`,
          name: b.title,
        },
      }
    }),
  }
}
interface BlogItemSchemaProps {
  blogPrefix: string
  blogItems: any[]
}
export const generateBlogItemsSchema = ({
  blogPrefix,
  blogItems,
}: BlogItemSchemaProps): any => {
  return {
    '@context': 'https://schema.org',
    '@type': 'ItemList',
    itemListElement: blogItems.map(
      (b: { slug: any; title: any }, i: number) => {
        return {
          '@type': 'ListItem',
          position: i + 1,
          item: {
            url: `https://www.getmegarummy.com${blogPrefix}/${b.slug}/`,
            name: b.title,
          },
        }
      }
    ),
  }
}

interface VideoSchemaProps {
  name: string
  description: string
  thumbnailUrl: string
  uploadDate: string
  duration?: string
  contentUrl?: string
  embedUrl?: string
}
export const generateVideoSchema = ({
  name,
  description,
  thumbnailUrl,
  uploadDate,
  duration,
  contentUrl,
  embedUrl,
}: VideoSchemaProps): any => {
  return {
    '@context': 'https://schema.org',
    '@type': 'VideoObject',
    name,
    description,
    thumbnailUrl,
    uploadDate,
    duration,
    contentUrl,
    embedUrl,
  }
}

export const generateFaqSchema = (faqs: Faq[]): any => {
  return {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: faqs.map(f => {
      return {
        '@type': 'Question',
        name: f.question,
        acceptedAnswer: {
          '@type': 'Answer',
          text: f.answer?.join(' ') || f.answerElement,
        },
      }
    }),
  }
}

export const generateOrgSchema = (): any => {
  return {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: 'Mega',
    url: 'https://www.getmegarummy.com',
    logo: `https://mega-prod-assets.getmega.com/getmega-website/getmega-logo.jpg`,
    sameAs: [
      'https://www.instagram.com/getmega/',
      'https://www.facebook.com/GetMegaApp/',
      'https://www.youtube.com/c/getmegaapp',
    ],
    contactPoint: {
      '@type': 'ContactPoint',
      contactType: 'Customer Support',
      email: 'help@getmega.com',
    },
  }
}

interface ContactSchemaProps {
  data: any
}
export const generateContactSchema = ({ data }: ContactSchemaProps): any => {
  return {
    '@context': 'https://schema.org',
    '@type': 'ContactPage',
    url: 'https://www.getmegarummy.com/contact-us/',
    headline: 'Contact Mega',
    description:
      'Send us your queries or drop in a line to Say Hi. Whatever be your message, we are listening.  Reachout to us via the form or through our social media handle!',
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': 'https://www.getmegarummy.com/contact-us/',
    },
    publisher: {
      '@type': 'Organization',
      '@id': 'https://www.getmegarummy.com/#organization',
      name: 'Mega',
      url: 'https://www.getmegarummy.com',
      sameAs: [
        'https://www.facebook.com/GetMegaApp/',
        'https://www.instagram.com/getmega/',
      ],
      logo: {
        '@type': 'ImageObject',
        '@id': 'https://www.getmegarummy.com/#logo',
        url: `https://www.getmegarummy.com${data.logo.publicURL}`,
        width: '164',
        height: '48',
      },
    },
  }
}

interface ProductSchemaProps {
  product?: unknown
}
export const generateProductSchema = ({
  product,
}: ProductSchemaProps): unknown => {
  return {
    '@context': 'https://schema.org',
    '@type': 'Product',
    name: `${product}`,
    description: 'Android Game',
    aggregateRating: {
      '@type': 'AggregateRating',
      ratingValue: '4.6',
      ratingCount: '51',
    },
  }
}
export const generateRummyProductSchema = () => {
  return {
    '@context': 'https://schema.org',
    '@type': 'Product',
    name: 'Mega Rummy',
    description: 'Android Game',
    aggregateRating: {
      '@type': 'AggregateRating',
      ratingValue: '4.6',
      ratingCount: '51',
    },
  }
}

export const generateRummyReviewSchema = (
  testimonials: TestimonialProps[]
): any => {
  return {
    '@context': 'https://schema.org/',
    '@type': 'SoftwareApplication',
    name: 'Mega',
    operatingSystem: 'ANDROID',
    applicationCategory: 'GameApplication',
    aggregateRating: {
      '@type': 'AggregateRating',
      ratingValue: '4.7',
      ratingCount: '51',
    },
    review: testimonials.map(t => {
      return {
        '@type': 'Review',
        reviewRating: {
          '@type': 'Rating',
          ratingValue: '5',
        },
        author: {
          '@type': 'Person',
          name: t.name,
        },
        datePublished: t.date,
        reviewBody: t.testimonial,
        publisher: {
          '@type': 'Organization',
          name: 'Mega',
        },
      }
    }),
  }
}

export const generateReviewSchema = (testimonials: TestimonialProps[]): any => {
  return {
    '@context': 'https://schema.org/',
    '@type': 'SoftwareApplication',
    name: 'Mega',
    operatingSystem: 'ANDROID',
    applicationCategory: 'GameApplication',
    aggregateRating: {
      '@type': 'AggregateRating',
      ratingValue: '4.7',
      ratingCount: '51',
    },
    offers: {
      '@type': 'Offer',
      price: '1.00',
      priceCurrency: 'INR',
    },
    review: testimonials.map(t => {
      return {
        '@type': 'Review',
        reviewRating: {
          '@type': 'Rating',
          ratingValue: '5',
        },
        author: {
          '@type': 'Person',
          name: t.name,
        },
        datePublished: t.date,
        reviewBody: t.testimonial,
        publisher: {
          '@type': 'Organization',
          name: 'Mega',
        },
      }
    }),
  }
}

export const generateBlogPostingSchema = ({
  title,
  description,
  publishedAt,
  createdAt,
  modifiedAt,
  articleBody,
  author,
  image,
  url,
}: {
  [index: string]: string
}): any => {
  return {
    '@context': 'https://schema.org',
    '@type': 'BlogPosting',
    headline: title,
    // alternativeHeadline: title,
    genre: 'Real Money Gaming',
    image,
    url,
    keywords: ['Poker'],
    wordcount: articleBody.split(/(\s+)/).length,
    publisher: {
      '@type': 'Organization',
      name: 'Mega',
      url: 'https://www.getmegarummy.com',
      logo: {
        '@type': 'ImageObject',
        url: 'https://www.getmegarummy.com/mega-logo-big.png',
        width: '120',
        height: '120',
      },
    },
    datePublished: publishedAt,
    dateCreated: createdAt,
    dateModified: modifiedAt,
    inLanguage: 'en-US',
    isFamilyFriendly: 'true',
    copyrightYear: '2019',
    copyrightHolder: 'Mega',
    description,
    articleBody,
    author: {
      '@type': 'Person',
      name: author,
    },
    mainEntityOfPage: 'True',
  }
}

export const generatePokerTrailerSchema = (): any =>
  generateVideoSchema({
    name: 'Poker Trailer',
    description: 'Poker Trailer',
    thumbnailUrl: 'https://i.vimeocdn.com/video/988751202_480.jpg',
    uploadDate: '2020-10-25',
    contentUrl: 'https://vimeo.com/475786286',
    embedUrl: 'https://player.vimeo.com/video/475786286',
    duration: 'T43S',
  })

export const generateRummyTrailerSchema = (): any =>
  generateVideoSchema({
    name: 'Rummy Trailer',
    description: 'Rummy Trailer',
    thumbnailUrl: 'https://i.vimeocdn.com/video/988750787.jpg',
    uploadDate: '2020-10-25',
    contentUrl: 'https://vimeo.com/475786338',
    embedUrl: 'https://player.vimeo.com/video/475786338',
    duration: 'T53S',
  })

export const generatePokerTutorialsVideoSchema = (): any[] => [
  generateVideoSchema({
    name: 'How To Play Poker 1/3',
    description: 'How To Play Poker 1/3',
    thumbnailUrl: 'https://i.vimeocdn.com/video/988733378.jpg',
    uploadDate: '2020-10-25',
    contentUrl: 'https://vimeo.com/475776127',
    embedUrl: 'https://player.vimeo.com/video/475776127',
    duration: 'T1M00S',
  }),
  generateVideoSchema({
    name: 'How To Play Poker 2/3',
    description: 'How To Play Poker 2/3',
    thumbnailUrl: 'https://i.vimeocdn.com/video/988734123.jpg',
    uploadDate: '2020-10-25',
    contentUrl: 'https://vimeo.com/475776557',
    embedUrl: 'https://player.vimeo.com/video/475776557',
    duration: 'T1M00S',
  }),
  generateVideoSchema({
    name: 'How To Play Poker 3/3',
    description: 'How To Play Poker 3/3',
    thumbnailUrl: 'https://i.vimeocdn.com/video/988734336.jpg',
    uploadDate: '2020-10-25',
    contentUrl: 'https://vimeo.com/475776752',
    embedUrl: 'https://player.vimeo.com/video/475776752',
    duration: 'T1M00S',
  }),
]

export const generateRummyTutorialsVideoSchema = (): any[] => [
  generateVideoSchema({
    name: 'How To Play Rummy 1/3',
    description: 'How To Play Rummy 1/3',
    thumbnailUrl: 'https://i.vimeocdn.com/video/988734581.jpg',
    uploadDate: '2020-10-25',
    contentUrl: 'https://vimeo.com/475776879',
    embedUrl: 'https://player.vimeo.com/video/475776879',
    duration: 'T1M00S',
  }),
  generateVideoSchema({
    name: 'How To Play Rummy 2/3',
    description: 'How To Play Rummy 2/3',
    thumbnailUrl: 'https://i.vimeocdn.com/video/988734910.jpg',
    uploadDate: '2020-10-25',
    contentUrl: 'https://vimeo.com/475777098',
    embedUrl: 'https://player.vimeo.com/video/475777098',
    duration: 'T1M00S',
  }),
  generateVideoSchema({
    name: 'How To Play Rummy 3/3',
    description: 'How To Play Rummy 3/3',
    thumbnailUrl: 'https://i.vimeocdn.com/video/988735014.jpg',
    uploadDate: '2020-10-25',
    contentUrl: 'https://vimeo.com/475777135',
    embedUrl: 'https://player.vimeo.com/video/475777135',
    duration: 'T1M08S',
  }),
]

export const generateDownloadInstructionVideoSchema = (): any =>
  generateVideoSchema({
    name: 'How To Install Mega',
    description: 'How To Install Mega',
    thumbnailUrl: 'https://i.vimeocdn.com/video/990626907.jpg',
    uploadDate: '2020-10-25',
    contentUrl: 'https://vimeo.com/475764833',
    embedUrl: 'https://player.vimeo.com/video/475764833',
    duration: 'T40S',
  })

export const generateCardsShowcaseVideoSchema = (): any =>
  generateVideoSchema({
    name: 'Cash Format Cards (Poker) Showcase',
    description: 'Cash Format Cards (Poker) Showcase',
    thumbnailUrl: 'https://i.vimeocdn.com/video/988731098.jpg',
    uploadDate: '2020-10-25',
    contentUrl: 'https://vimeo.com/475774774',
    embedUrl: 'https://player.vimeo.com/video/475774774',
    duration: 'T1M20S',
  })

export const generateCasualShowcaseVideoSchema = (): any =>
  generateVideoSchema({
    name: 'Cash Format Casual Showcase',
    description: 'Cash Format Casual Showcase',
    thumbnailUrl: 'https://i.vimeocdn.com/video/988729808.jpg',
    uploadDate: '2020-10-25',
    contentUrl: 'https://vimeo.com/475773841',
    embedUrl: 'https://player.vimeo.com/video/475773841',
    duration: 'T1M51S',
  })

export const generateTriviaShowcaseVideoSchema = (): any =>
  generateVideoSchema({
    name: 'Cash Format Trivia Showcase',
    description: 'Cash Format Trivia Showcase',
    thumbnailUrl: 'https://i.vimeocdn.com/video/988732668.jpg',
    uploadDate: '2020-10-25',
    contentUrl: 'https://vimeo.com/475775497',
    embedUrl: 'https://player.vimeo.com/video/475775497',
    duration: 'T1M47S',
  })

export const generateReferralVideoSchema = (): any =>
  generateVideoSchema({
    name: 'Mega Referral',
    description: 'Mega Referral',
    thumbnailUrl: 'https://i.vimeocdn.com/video/988737017.jpg',
    uploadDate: '2020-10-25',
    contentUrl: 'https://vimeo.com/475778360',
    embedUrl: 'https://player.vimeo.com/video/475778360',
    duration: 'T25S',
  })

export const generateGangsOfGamersVideoSchema = (): any[] => [
  generateVideoSchema({
    name: 'Mega: Install, Play, Win, Withdraw, Repeat!️',
    description:
      'Mega se cash lena is faster than an ATM! Khelo Rummy, jeeto money, transfer karo fataafat.',
    thumbnailUrl: 'https://i.vimeocdn.com/video/988733378.jpg',
    uploadDate: '2021-07-21',
    contentUrl: 'https://vimeo.com/577600602',
    embedUrl: 'https://player.vimeo.com/video/577600602',
    duration: 'PT45S',
  }),
  generateVideoSchema({
    name: 'Mega Rummy️',
    description: 'Mega Rummy️',
    thumbnailUrl: 'https://i.vimeocdn.com/video/988733378.jpg',
    uploadDate: '2021-07-21',
    contentUrl: 'https://vimeo.com/577599725',
    embedUrl: 'https://player.vimeo.com/video/577599725',
    duration: 'PT40S',
  }),
  generateVideoSchema({
    name: 'Rummy On Mega️',
    description: 'Rummy On Mega️',
    thumbnailUrl: 'https://i.vimeocdn.com/video/988733378.jpg',
    uploadDate: '2021-07-21',
    contentUrl: 'https://vimeo.com/577601590',
    embedUrl: 'https://player.vimeo.com/video/577601590',
    duration: 'PT49S',
  }),
  generateVideoSchema({
    name: 'Mega Pe Rummy️',
    description: 'Mega Pe Rummy️',
    thumbnailUrl: 'https://i.vimeocdn.com/video/988733378.jpg',
    uploadDate: '2021-07-21',
    contentUrl: 'https://vimeo.com/577602755',
    embedUrl: 'https://player.vimeo.com/video/577602755',
    duration: 'PT21S',
  }),
]

export const generateBlogVideoSchema = (element: HTMLDivElement): any => {
  const embeddedIframes = element.getElementsByTagName('iframe')

  if (embeddedIframes && embeddedIframes.length > 0) {
    return Array.from(embeddedIframes)
      .map(vid => {
        const isVideo = vid.hasAttribute('data-schema-video')
        // required fields name, description, thumbnailUrl, uploadDate
        const name = vid.getAttribute('data-schema-name')
        const description = vid.getAttribute('data-schema-description')
        const thumbnailUrl = vid.getAttribute('data-schema-thumbnailUrl')
        const uploadDate = vid.getAttribute('data-schema-uploadDate')

        if (isVideo && name && description && thumbnailUrl && uploadDate) {
          return generateVideoSchema({
            name,
            description,
            thumbnailUrl,
            uploadDate,
            contentUrl: vid.getAttribute('data-schema-url') || undefined,
            embedUrl: vid.getAttribute('src') || undefined,
            duration: vid.getAttribute('data-schema-duration') || undefined,
          })
        }
        return null
      })
      .filter(a => a)
  }
  return undefined
}
