import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import './ResponsibleGaming.scss'
import Container from 'react-bootstrap/esm/Container'
import Row from 'react-bootstrap/esm/Row'
import Col from 'react-bootstrap/esm/Col'

const ResponsibleGaming: React.FC = () => {
  const data = useStaticQuery(graphql`
    {
      quote: file(relativePath: { eq: "noun-quote.png" }) {
        childImageSharp {
          gatsbyImageData(width: 174, layout: CONSTRAINED)
        }
      }
      kyc: file(relativePath: { eq: "kyc-verification.png" }) {
        childImageSharp {
          gatsbyImageData(width: 120, layout: CONSTRAINED)
        }
      }
      plus18: file(relativePath: { eq: "18plus.png" }) {
        childImageSharp {
          gatsbyImageData(width: 120, layout: CONSTRAINED)
        }
      }
      aigf: file(relativePath: { eq: "aigf-round.png" }) {
        childImageSharp {
          gatsbyImageData(width: 120, layout: CONSTRAINED)
        }
      }
    }
  `)

  return (
    <div id="responsible-gaming">
      <div className="header">
        <Container>
          <h1>Responsible Gaming</h1>
          <GatsbyImage
            image={data.quote.childImageSharp.gatsbyImageData}
            loading="eager"
            alt="quote"
            className="quote-image"
          />
          <div>
            <Row>
              <Col md={8} className="quote">
                <span>At MegaRummy, </span>
                <span>we want you to enjoy </span>
                <span>our platform &amp; games </span>
                <span>
                  <span className="underline">responsibly.</span>
                </span>
              </Col>
            </Row>
          </div>
        </Container>
      </div>

      <div className="steps">
        <Container>
          <div className="step1">
            <div className="description">
              At MegaRummy, we want you to enjoy our platform and games
              responsibly. As a regulated, responsible and professionally built
              gaming platform, we take responsible gaming seriously and believe
              in providing a safe, secure and reliable environment to all our
              users.
            </div>
            <div className="description">
              We strive to assist and protect players who are at risk of the
              potential negative effects of gaming through a number of means,
              including player age verification and guidance on responsible
              gaming.
            </div>
          </div>

          <div className="step2">
            <GatsbyImage
              image={data.plus18.childImageSharp.gatsbyImageData}
              loading="eager"
              alt="18+"
              className="step-icon"
            />
            <h2>Age Verification</h2>
            <div className="description">
              <p>
                Every person signing up for a new account must check a box that
                indicates that they are at least 18 years of age. This notifies
                everybody that we don’t accept players under 18.
              </p>
              <p>
                When a player creates an account with us, we collect their name,
                address, and birth date to confirm that the player is at least
                18-years-old.
              </p>
              <p>
                Any underage player who has provided dishonest or inaccurate
                information regarding their true age may have all winnings
                forfeited and could face criminal prosecution.
              </p>
              <p>
                We do not target underage players with our marketing and
                advertising.
              </p>
            </div>
          </div>

          <div className="step3">
            <GatsbyImage
              image={data.aigf.childImageSharp.gatsbyImageData}
              loading="eager"
              alt="aigf"
              className="step-icon"
            />
            <h2>We are member of AIGF</h2>
            <div className="description">
              We are a member of the All India Gaming Federation (AIGF), with
              the purpose of self-regulating all Online Games of Skill within
              the territory of the Republic of India. The AIGF Charter can be
              found{' '}
              <a
                href="https://www.aigf.in/skillgamescharter/"
                rel="noopener noreferrer nofollow"
                target="_blank"
              >
                here
              </a>
              .
            </div>
          </div>
        </Container>
      </div>

      <div className="user-responsibilities">
        <Container>
          <h2>What you can do to help yourself?</h2>
          <div className="text">
            If you choose to play online, there are some general guidelines that
            can help make your playing experience safer, and reduce the risk of
            problems occurring:
          </div>
          <ol>
            <li>Play for entertainment, and not as a way of making money.</li>
            <li>Make frequent withdrawals when you win.</li>
            <li>
              Play with money that you can afford to lose. Never use money that
              you need for your necessities like rent, tuition, food, etc.
            </li>
            <li>
              Set deposit limits and ensure you never deposit more than you can
              afford to lose.
            </li>
            <li>
              Do not try to compensate for your losses by playing higher stakes.
            </li>
            <li>
              Don’t play when you are upset, tired, or depressed. It is
              difficult to make good decisions when you are feeling down.
            </li>
            <li>
              Balance your playing with other activities. Find other forms of
              entertainment so playing does not become too big a part of your
              life.
            </li>
            <li>
              Players can call customer care if they want to self-exclude
              themselves from online rummy, this way players can temporarily
              restrict themselves from online gaming.
            </li>
            <li>
              If you or someone you know is experiencing problems with
              responsible Rummy playing, it&apos;s crucial to seek help
              promptly.
              <a
                href="https://www.gameprudence.com/call-back.html"
                rel="noopener noreferrer nofollow"
                target="_blank"
              >
                Game Prudence
              </a>{' '}
              offers assistance for such issues, and you can register your
              request through Game Prudence to get the support you need.
            </li>
          </ol>
        </Container>
      </div>

      <div className="h-40" />
    </div>
  )
}

export default ResponsibleGaming
