import React from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { Breadcrumb } from '../components/Breadcrumbs/Breadcrumbs'
import Responsible from '../components/ResponsibleGaming/ResponsibleGaming'
import { RESPONSIBLE_GAMING as RESPONSIBLE_GAMING_LINK } from '../components/internal-links'
import { generateBreadcrumbsSchema } from '../lib/generate-schema'

const breadcrumbs: Breadcrumb[] = [
  {
    title: 'Homepage',
    url: '/',
  },
  {
    title: 'Responsible Gaming',
    url: RESPONSIBLE_GAMING_LINK,
  },
]

const ResponsibleGaming: React.FC = () => (
  <Layout showBreadcrumbs breadcrumbs={breadcrumbs}>
    <Helmet>
      {process.env.GATSBY_STAGING === 'true' && (
        <meta name="robots" content="noindex" />
      )}
    </Helmet>
    <SEO
      title="Resposible Gaming: Read How to Play Real Money Gaming Responsibly"
      description="Read how to play responsibly on Mega. Mega is an entertainment platform and encourages all its users to play, learn, earn, and have fun. Click to read more!"
      breadcrumbsSchema={generateBreadcrumbsSchema(breadcrumbs)}
    />
    <Responsible />
  </Layout>
)

export default ResponsibleGaming
